// import React from "react";

export const general =
{
  title: "JuSt Innovation",
  linkedin: "https://www.linkedin.com/in/stefan-junk/",
  linkedinShort: "/stefan-junk/",
  mailto: "mailto:stefan@stefanjunk.com",
  email: "stefan@stefanjunk.com",
  location: "Berlin, Germany",
  locationUrl: "https://goo.gl/maps/s8b9D1P4ovEUXsQz7",
  summary: "With the finger on the pulse of science and with experiences across several business domains, a holistic integration of business and technology is mandatory for me. For high customer satisfaction I enjoy most to work closely and in direct communication with the client. I am best in a fast-paced and yet creative work culture with personal touchpoints and a high degree of personal responsibility.",
  tools: "I used a variety of tools in my past activities. This is an extract of the most relevant ones."
}

export const hero =
{
  buzzwords: ["Solution Design", "Cloud Computing", "Quantum Computing", "Machine Learning"],
  greeting: ["JuSt Innovation"],
  introduction: ["Web Presentation is coming soon."],
  // introduction: "Innovator - Digital Architect - Business Designer"
}

export const education = [
  {
    institution: "Ruhruniversität",
    location: "Bochum",
    degree: "Master of Science",
    details: "Electrical Engineering & Information Technology",
    from: "2009-04-01",
    to: "2011-09-30",
  },
  {
    institution: "Technische Fachhochschule Georg Agricola",
    location: "Bochum",
    degree: "Diplomingenieur (FH)",
    details: 'Electrical Engineering & Information Technology',
    // "Ausbildung plus Studium" program of RWE Power AG - Combined Studies and Apprenticeship',
    from: "2005-08-01",
    to: "2009-02-28",
  },
  {
    institution: "RWE Power AG",
    location: "Bergheim",
    degree: "Elektroniker für Betriebstechnik (IHK)",
    details: '"Ausbildung plus Studium" program of RWE Power AG - Combined Studies and Apprenticeship',
    from: "2005-08-01",
    to: "2008-06-30",
  },
  // {
  //   institution: "Werner von Siemens Berufskolleg",
  //   location: "Köln",
  //   degree: "Allgemeine Hochschulreife (AHR)",
  //   details: "",
  //   from: "2003-08-01",
  //   to: "2004-07-31",
  // },
  // {
  //   institution: "Adolf-Kolping Berufskolleg",
  //   location: "Kerpen",
  //   degree: "Fachhochschulreife (FHR)",
  //   details: "",
  //   from: "2000-08-01",
  //   to: "2003-07-31",
  // },
  {
    institution: "Adolf-Kolping Berufskolleg",
    location: "Kerpen",
    degree: "Informatonstechnischer Assistent (ITA)",
    details: "",
    from: "2000-08-01",
    to: "2003-07-31",
  },
];

export const experiences = [
  {
    title: "Cloud Advisory Consultant",
    company: "Accenture",
    location: "Berlin",
    from: "2020-09-01",
    to: "now",
    description: "Hybrid and multi cloud transformation projects in financial services.",
    bullets: [
      "Stakeholder management between cloud service providers and the client",
      "Requirements and delivery management - shaping and tracking work packages along the migration preparation",
      "Migration planning, landing zone design and cloud integration architecture for Google Cloud Platform and Microsoft Azure",
    ]
  },
  {
    title: "Freelancer",
    company: "JuSt Innovation",
    location: "Berlin",
    from: "2020-04-01",
    to: "2020-08-31",
    description: "Cloud engineering services focusing on hybrid infrastructure.",
    bullets: [
      "Service preparation and API design for migration to Google Cloud Platform",
      "Network design as Infrastructure-as-Code for hybrid cloud setup and staging environments in Amazon Web Services",
    ]
  },
  {
    title: "Co-Founder",
    company: "DNL Deep Neuron Lab GmbH",
    location: "Berlin",
    from: "2019-04-01",
    to: "2020-03-31",
    description: "Company foundation, technical lead and product design for DNL. DNL provides a Software-as-a-Service solution to extract financial data from annual reports using latest findings of natural language processing and other machine learning techniques.",
    bullets: [
      "Business plan design and evaluation of the underlying assumptions, as well as refinement of the business model and the product",
      "Funding application resulting in 350k€ worth of public funding and a business angel",
      "Team formation, including recruitment of five persons: an additional co-founder, a data scientist, two developer and a marketing expert",
      "Development of the minimal viable product, including the design of the software architecture, the user interface, machine learning models, hosting and operation",
    ]
  },
  {
    title: "Research Assistant",
    company: "Chair of Space Technology, Technical University Berlin",
    location: "Berlin",
    from: "2014-10-01",
    to: "2018-03-31",
    description: "Design of the hardware and power management solution for the modular nano-satellite bus TUBiX20. The design started from scratch up to the launched and operational prototype TechnoSat, carrying a payload for earth observation to detect wildfire.",
    bullets: [
      "Design-to-cost for reliability, focusing on fault-tolerance, radiation-tolerance, disaster recovery while maintaining launch mass and inertia relevant mass.",
      "Requirements analysis of orbit related power and temperature cycles, as well as power demand and components lifetime were conducted.",
      "Responsible for the component design, prototyping, flight model production, subsystem and system testing, as well as supplier management and quality assurance",
    ],
  },
  {
    title: "Research Assistant",
    company: "Fraunhofer Institute for Reliability and Microintegration (IZM)",
    location: "Berlin",
    from: "2011-09-01",
    to: "2014-04-30",
    description: "Consulting on onboard high voltage power systems for international automotive and railway companies regarding electromagnetic compatibility (EMC).",
    bullets: [
      "Analysis and design optimization of high voltage (HV) power systems and components of electric cars, focusing on chargers, battery pack and HV cables.",
      "Analysis and design optimization of a drive Converter of a electric train, focusing on electromagnetic interferences (EMI) and misbehavior in HV components.",
    ],
  },
  {
    title: "Thesis & Working Student",
    company: "Siemens AG, Wilo SE, Novero GmbH, RWE Power AG",
    location: "",
    from: "2008-09-01",
    to: "2011-08-31",
    description: "",
    bullets: [
      "Master Thesis, Siemens, Krefeld: Development of a boost converter design for the onboard power supply of trams to reduce losses and cooling demand",
      "Working Student, Wilo SE, Dortmund: Product Engineering for electronically controlled heating water pumps",
      "Working Student, Novero, Bochum: Design of automated testing systems for automotive speaker phone system",
      "Diploma Thesis, RWE Power AG: Design of a control system to maximize the addition of municipal waste to the lignite combustion process while maintaining proportion of HCl in the flue gas",
    ],
  },
]

export const keyFacts = {
  "Technologies": [
    {
      title: "First Skill",
      subtitle: "super skill",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    },
    {
      title: "Second Skill",
      subtitle: "super skill",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    },
  ],
  "Skills": [
    {
      title: "First Skill",
      subtitle: "super skill",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    },
    {
      title: "Second Skill",
      subtitle: "super skill",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    },
  ],
}
export const testimonials =
{
  buzzwords: ["Cloud Computing", "Quantum Computing", "Machine Learning"],
  introduction: "Digital Architect - Innovator - Buisness Designer"
}