import React, { FC } from "react";
import { hero } from "../data";
// import ConnectBar from "./ConnectBar";
// import Buzzword from "./Buzzword";
// import portrait from "../images/portrait-sw.png"
// import { fadeIn } from "../styles/global"
import { AnimationOnScroll } from 'react-animation-on-scroll'; // animate.css

const About: FC = () => {
  // export default function About(props) {

  // const portraitStyle = `
  // width: 30
  // `

  return (
    <section id="about" className="bg-[length:20vh] md:bg-[length:40vh] bg-no-repeat bg-fixed">
      {/* style={{ backgroundPosition: "90% 50%", backgroundImage: `url(${portrait})` }}> */}
      {/* bg-right backgroundSize: "40vh", */}
      <div className="relative container  p-3 mx-auto md:px-6 h-[80vh] md:h-screen flex flex-wrap lg:flex-nowrap justify-center">
        <div className="flex flex-col justify-evenly grow pb-56 md:pb-24 lg:pb-0 m-5 md:w-1/2 lg:ml-20 md:ml-12 ml-10 lg:mr-0 md:mr-16">
          <div className="relative w-2/3 before:block before:bg-secondary before:h-0.5 lg:before:w-14 md:before:w-10 before:w-6 before:absolute lg:before:-left-20 md:before:-left-12 before:-left-8 xl:before:top-7 lg:before:top-6 md:before:top-4 sm:before:top-3 before:top-3">
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOut="animate__fadeOutLeft">
              <span className="xl:text-3xl lg:text-2xl md:text-xl sm:text-lg text-lg font-normal">
                {hero.greeting}
                <br />
                <br />
              </span>
              <span className="lg:text-lg text-sm font-normal leading-none text-justify">
                {hero.introduction}
              </span>
            </AnimationOnScroll>
            {/* <AnimationOnScroll animateIn="animate__fadeInUp" animateOut="animate__fadeOutUp">
              <span className="lg:text-lg text-sm font-normal leading-none text-justify">
                {hero.introduction}
              </span>
            </AnimationOnScroll> */}
          </div>

          <div className="mt-10">
            {/* className="hidden lg:block"> */}
            {/* <AnimationOnScroll animateIn="animate__fadeInUp" animateOut="animate__fadeOutUp">
              <ConnectBar />
            </AnimationOnScroll> */}
          </div>

        </div>
      </div>
      {/* <Buzzword /> */}
    </section>
  );
}

export default About