import React, { FC } from "react";
import Hero from "../components/Hero";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
// import Projects from "../components/Projects";
import Portfolio from "../components/Portfolio";
import Experiences from "../components/Experiences"
import Tools from "../components/Tools"
// import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import { SEO } from "../components/SEO"

const IndexPage = () => {
    return (
        <main id="home" className="text-primary font-gothic antialiased">
            {/* <Navbar /> */}
            <Hero />
            {/* <Portfolio /> */}
            {/* <Experiences /> */}
            {/* <Tools /> */}
            {/* <Contact /> */}
            <Footer />
            <a href="#home"
                type="button"
                className="fixed top-[85vh] left-[80vw] md:left-[90vw] p-3 bg-background text-primary font-medium text-xs leading-tight uppercase rounded-full shadow-md ring-0 hover:ring-2 hover:ring-primary active:ring-2 active:ring-primary transition duration-200 ease-in-out "
            >
                <svg aria-hidden="true" focusable="false" data-prefix="fas" className="w-4 h-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"></path></svg>
            </a>
        </main>
    )
}


export const Head = () => (
    <SEO />
)

export default IndexPage

// npx gatsby clean
// npx gatsby build
// firebase deploy


// https://blog.logrocket.com/using-gatsby-with-tailwind-css-a-tutorial-with-examples/#setting-up-a-gatsby-project
// https://www.freecodecamp.org/news/build-portfolio-website-react/


// ###Animation
// https://www.npmjs.com/package/react-animation-on-scroll
// https://animate.style/

// # Examples
// https://templateflip.com/demo/templates/creative-cv/
// https://templateflip.com/templates/creative-cv/
