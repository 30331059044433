import React, { FC } from "react";
import { Link } from "gatsby";

export default function Footer() {
  return (

    <footer className="p-4 pb-6 z-10 w-screen bg-background md:flex md:items-center md:justify-between md:p-6">
      {/* p-4 bg-white rounded-lg shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 */}
      <div className="text-sm text-primary sm:text-center">© 2022 JuSt Innovation. All Rights Reserved.
      </div>
      <ul className="flex flex-wrap items-center mt-3 text-sm text-primary sm:mt-0 md:pr-20">
        <li>
          {/* <a href="#" className="mr-4 hover:underline md:mr-6">Privacy Policy</a> */}
          <Link className="mr-4 hover:text-primary md:mr-6 text-base text-inactive font-medium" to="/privacy/">Privacy Policy</Link>
        </li>
        <li>
          {/* <a href="#" className="mr-4 hover:underline md:mr-6">Imprint</a> */}
          <Link className="mr-4 hover:text-primary md:mr-6 text-base text-inactive font-medium" to="/imprint/">Imprint</Link>
        </li>
      </ul>
    </footer>

  );
}